@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@media print {
  .margem {
    margin-top: 10px;
    font-size: 12px !important;
  }
  
  .img-topo {
    padding-top: 40px
  }

  ::ng-deep .mat-horizontal-stepper-header-container, .telefoneComissao *{
    display: none !important;
  }

  .page-break {
    display: block;
    page-break-before: always;
  }

   body{print-color-adjust: exact !important;}
    .no-print, .no-print *{ display: none !important; }


  .planoFinanceiro {
    font-size: 3em !important
  }

  .planoFotos {
    font-size: 1em !important
  }

  .bgVerde,
  .bgVermelho,
  .bgAzul,
  .bgAzulEscuro,
  .esquerda,
  * {
    font-size: 1em !important;
    color: #000 !important
  }

  .telefoneComissaoPrint {
    display: block !important;
  }

  .descritivoFinanceiro {
    font-size: 12px !important;
  }

  .odd {
    background-color: #dee2e6 !important;
  }

  body {
    margin: 0;
    padding: 0;
  }
}

.odd {
  background-color: #dee2e6 !important;
}

.telefoneComissaoPrint {
  display: none;
}

.bgRoxo {
  background-color: rgb(181, 151, 185) !important;
}

.back {
  left: 2em;
  top: 5px;
  position: absolute;
  cursor: pointer;
  color: #e1388d;
  transition: ease-in, 0.7s
}

.ma-mdc-tab-label-container {
  background-color: #535353;
  padding: 10px
}

.topVoltar {
  width: 100%;
  background-color: #ff2c96;
  color: #fff;
  text-align: left;
  padding: 10px 0px 10px 90px;
}

.topVoltar fa-icon:hover {
  cursor: pointer;
  text-align: left;
}

.fixo {
  padding-left: 5px;
  position: fixed;
  top: 0;
  z-index: 99;
}

.bgInicio {
  height: 100vh;
  width: 100vw;
  background-image: url('./assets/img/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.contentHome {
  width: 85%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}

.menuCol {
  align-self: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 250px;
  margin: 10px;
  height: 160px;
  text-align: center;
  color: white;
  cursor: pointer;
  padding-top: 25px;
  font-size: 1.5em;
  font-weight: 500;
  border-radius: 10px;
  transition: all .30s;
}

.menuCol:hover {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 35px;
  -moz-transition: all .30s;
  -o-transition: all .30s;
  -webkit-transition: all .30s;
  transform: scale(0.9, 0.9);
  transition: all .30s;
}

.title {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin-top: -5em;
  font-size: 2em;
  font-weight: bold;
  color: #787878;
}

.legendaContent {
  font-size: 14px;
  text-align: center;
  top: -20px;
  position: relative;
  font-weight: 400;
}

.topoLogo {
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: -moz-linear-gradient(top, #fff 0%, #e8dfe5 100%);
  background: -webkit-linear-gradient(top, #fff 0%, #e8dfe5 100%);
  background: linear-gradient(to bottom, #fff 0%, #e8dfe5 100%);
}

.btnInner {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.btnInner button {
  margin: 5px;
}

::ng-deep .mat-horizontal-stepper-header-container #cdk-step-label-0-8 {
  pointer-events: none !important;
}

.full-width {
  padding-left: 4em;
  width: 100%
}

.padding-width {
  padding-left: 4em;
  width: 90%;
}

.descricaoSelecionavel {
  font-size: 0.85em;
  color: rgb(65, 65, 65);
  text-align: justify;
  padding-left: 1.2em;
  padding-right: 5em;
  height: 100%;
  margin-bottom: 1em;
}

.stepperButtons {
  position: static;
  width: 100%;
  height: 50px;
  text-align: center;
  margin-top: 20px;
}

.turmasSelect:hover {
  cursor: pointer;
  background-color: rgba(57, 127, 192, 0.383) !important;
}

.turmasSelected {
  cursor: pointer;
  background-color: rgba(104, 63, 121, 0.7) !important;
}

.enunciados {
  background-color: #795896;
  padding: 10px;
}

.enunciados h4 {
  color: white;
  font-size: 2.5em;
  text-align: center;
  margin-right: 10px;
  float: right
}

h4 {
  color: #b61b69;
  font-size: 1.5em;
  text-align: center;
}

.modalFotos {
  position: fixed;
  margin: auto;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  z-index: 1;
  padding: 5px;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }

  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

.close {
  z-index: 99;
  position: absolute;
  right: 30px;
  color: #f4eff5;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.prev {
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 40%;
  left: 8px;
  right: auto;
  color: rgb(199, 199, 199);
  font-size: 4em;
}

.next {
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 40%;
  left: auto;
  right: 8px;
  color: rgb(199, 199, 199);
  font-size: 4em;
}

.next:hover,
.prev:hover {
  color: rgb(233, 233, 233);
}

.imagemOrcamento {
  width: 100%;
  padding-top: 60px;
}

.planoFotos {
  font-size: 1em;
  width: 90%;
  margin-left: 55px;
  border-radius: 25px;
  border: 2px;
  border-color: #bf7cbf;
  border-style: solid;
  text-align: center;
  padding: 25px;
}

.planoFinanceiro {
  font-size: 4em;
  width: 90%;
  margin-left: 55px;
  border-radius: 25px;
  border: 2px;
  border-color: #bf7cbf;
  border-style: solid;
  text-align: center;
  padding: 25px;
}

.capaOrcamento {
  font-size: 1em;
  width: 90%;
  margin-left: 55px;
  border-radius: 25px;
  border: 2px;
  border-color: #bf7cbf;
  border-style: solid;
  text-align: center;
  padding: 25px;
}

.tableConvites {
  border: 2px solid #bf7cbf;
}

.tableConvites tr td {
  border: 1px solid #bf7cbf;
}

.print {
  right: 5px;
  top: 5px;
  position: absolute;
  cursor: pointer;
  color: rgb(46, 46, 46);
}

label {
  font-size: 0.85em;
  color: rgb(107, 107, 107);
  font-weight: 500;
}

.bgVerde {
  background-color: #00ea69;
  font-size: 1.5em;
  color: #000;
  text-align: center;
}

.bgAzul {
  background-color: #01b0f1;
  font-size: 1.5em;
  color: #000;
  text-align: center;
}

.bgAzulEscuro {
  background-color: #2e61af;
  font-size: 1.5em;
  color: #000;
  text-align: center;
}

.bgVermelho {
  background-color: #fe0002;
  font-size: 1.5em;
  color: #000;
  text-align: center;
}

.baile {
  font-size: 1.2em !important;
  color: #000 !important;
}

.esquerda {
  text-align: center;
  font-size: 1.5em;
}

.containerTurmas {
  padding-left: 5em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
}

.filtroContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin: 3em 0em;
}

.turmaBox {
  width: 18em;
  height: 11.5em;
  margin: 5px;
  border-radius: 5px;
  background: rgb(254, 255, 232);
  background: -moz-linear-gradient(top, rgba(254, 255, 232, 1) 0%, rgba(214, 219, 191, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(254, 255, 232, 1) 0%, rgba(214, 219, 191, 1) 100%);
  background: linear-gradient(to bottom, rgba(254, 255, 232, 1) 0%, rgba(214, 219, 191, 1) 100%);
}

.turmaBox:hover {
  border: solid 3px #ffffff94;
  border-radius: 15px;
  transition: all .2s;
  cursor: pointer;
}

.turmaBox p {
  text-align: center;
  margin: 0;
  font-size: 0.8em;
}

.turmaBox h6 {
  text-align: center;
  font-size: 0.8em;
  padding: 2px;
  color: #4b117e
}

.turmaBox h5 {
  text-align: center;
  font-size: 0.75em;
  margin: 0;
  padding-top: 5px;
  font-weight: 500;
  color: #51326d
}

.modal {
  z-index: 99;
  margin: auto;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgb(250, 250, 250);
  overflow-y: auto;
}

.cadastroOption {
  background-color: rgb(236, 236, 236);
  border-radius: 8px;
  padding: 10px;
  font-size: 0.85em;
  width: 98%;
  margin: 0 auto;
}

.cadastroOption h4 {
  text-align: center;
  color: rgb(41, 41, 41);
  font-size: 1.1em;
}

.modal h5 {
  text-align: center;
  color: rgb(138, 91, 143);
  font-size: 1.2em;
  margin-bottom: 20px;
}

.modalDetails {
  z-index: 90;
  position: fixed;
  margin-left: auto;
  overflow-y: auto;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgb(250, 250, 250);
}

.modalDetails h5 {
  text-align: center;
  color: rgb(138, 91, 143);
  font-size: 1.2em;
  margin-bottom: 20px;
}

.closeDetails {
  z-index: 99;
  position: absolute;
  top: 15px;
  right: 35px;
  color: #9840a3;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.closeDetails:hover,
.closeDetails:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.relatorioForm {
  padding: 5px;
  border-radius: 10px;
  background-color: white;
}

.imagemLightbox {
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
  transition: all .30s;
}

.containerGaleria {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}

.imagemGaleria {
  padding: 3px;
  border-radius: 5px;
  height: 200px;
  cursor: pointer;
  transition: 0.3s;
}

.imagemGaleria:hover {
  border-radius: 20px;
}

.img-container {
  display: inline-block;
  position: relative;
}

.delButton {
  display: none;
  position: absolute;
}

.delButtonShow {
  display: block;
  position: absolute;
  right: 35px;
  top: 10px;
}

.modalDoc {
  position: fixed;
  margin: auto;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  z-index: 99;
  padding: 5px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

.labelRelatorio {
  padding: 5px;
  color: rgb(97, 94, 94);
  font-weight: 500;
}

.modalSenha {
  z-index: 99999 !important;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.65);
}

.modalSenha .box {
  padding: 100px;
  width: 800px;
  height: 500px;
  border-radius: 20px;
  background-color: white;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::ng-deep .mat-tooltip {
  white-space: pre-line;
  font-size: 1em;
}

.scrollTable {
  overflow-y: auto;
  border-radius: 10px;
}

.scrollTable::-webkit-scrollbar {
  width: 0.5em;
  background: #720a8c;
  border-radius: 10px;
}

.scrollTable::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 10px;
}

.legenda {
  width: 85%;
  padding: 2em;
  margin: 0 auto;
  font-size: 0.85em;
  font-weight: bold;
}

.legenda .coluna {
  padding: 10px;
  text-align: center;
  border: solid 1px #000;
}

.check {
  width: 35px;
  height: 35px;
  position: absolute;
  text-align: center;
  margin-top: 10px;
  margin-left: 16.5em;
}

.folders {
  width: calc(100%-6em);
  margin-left: 6em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}

.folder {
  width: 18em;
  height: 11.5em;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  background: #f3f3f3;
  box-shadow: 0px 0px 0px 5px #eee;
}

.folder p {
  text-align: center;
  font-weight: bold;
}

.folder:hover {
  border: solid 3px #e1388c;
  background: #e66ba9;
  color: #fff;
  border-radius: 30px 0px 30px 0px;
  cursor: pointer;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.containerTurmasModal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  padding-top: 3em;
}

.margem {
  margin-top: 4em;
  margin-right: 15px;
}

.img-topo {
  width: 100%;
  text-align: center;
  padding-top: 40px
}

.fotoDafoto {
  background: url('./assets/img/foto-foto.png') no-repeat;
  background-size: cover;
  min-height: 268px;
  max-height: 268px;
  border-radius: 20px;
}

.descritivoFinanceiro {
  font-size: 18px;
  font-weight: 500;
  margin: 0 auto;
}

.imagemF {
  margin-left: 10px;
  width: 100%;
  margin-bottom: 10px;
}

td,
th {
  text-align: center;
  vertical-align: middle;
}